export const defaultChainId = 97;

export const rpcUrls = {
  1: 'https://mainnet.infura.io/v3/' + process.env.REACT_APP_INFURA_PROJECT_ID,
  4: 'https://rinkeby.infura.io/v3/' + process.env.REACT_APP_INFURA_PROJECT_ID,
  56: 'https://bsc-dataseed1.binance.org',
  97: 'https://data-seed-prebsc-1-s1.binance.org:8545',
}

export const networkNames = {
  1: 'Ethereum Mainnet',
  4: 'Rinkeby Test Network',
  56: 'BSC Main Network',
  97: 'BSC Test Network',
}

export const addresses = {
  ineryToken : {
    // 97: '0x3710cd84e0e331c99c70a34b8c6c136c2cdf91c4', joyeva adresa
   // 97: '0x38D1d4b10628D5f3460757beD3215e2D85deFb3f',
   97: '0xc2B8815Df07a64Af062a7957d43639efc1729E21'
  },
  advisorVesting: {
    //97: '0x1212c59495B6Cd761Cd44162E0541D5B4F03A51E',
    97: '0x02FBb73D8A7B2568DE5De464ce00985928eB1bEf'
  },
  developmentVesting: {
    //97: '0x1FeB7491667652a123401B3591Bb6611012B8c56',
    97: '0x82716f5bd77ab8b763989f815D4250d97d63D852'
  },
  marketingVesting: {
    //97: '0xb663be6454bB216DEe31B27b0F6F0aDa6E33D5eB',
    97: '0xd7323aDeB0d078c4FAC2fCC535a90E572922a760'
  },
  preseedVesting: {
    //97: '0x4eDb3BC66Bb7b33c299CA40E4F49Ad5243811809',
    97: '0xEB8bED25BaeB3C1E3473feD1Be3fe2001F9aFBaa'
  },
  privateAVesting: {
    //97: '0x11112De029b68004bA088F804EB3B077E4689e8E',
    97: '0xB9A792d3a82a1289A6D763bc1abbaeDB52daA4FA'
  },
  publicVesting: {
    //97: '0x822FD53BFC8c9E593cFbFB5172f4D4C2437fc88B',
    97: '0x0240EC85FDdf75Aa88b6cc4dA59535a9a448948D'
  },
  seedVesting: {
    //97: '0x9FaB82a3827ca92079503AC46Ab27b02b9f1571A',
    97: '0x9589a64f18FeF43059CAe042a9eb958616Ca1006'
  },
  strategicVesting: {
    //97: '0x3A8d98aF180B88610Ba717983b8220ED23215B74',
    97: '0xBfDdbb108a66e9a7E2a7517C0Dee26c69a23575e'
  },
  teamVesting: {
    //97: '0xFdC8CBB61B1b6Cb7a08AaC76A317D85BdE0221ac',
    97: '0x4C9Fb27732A4B19a7a578D328AE188e73b943C8d'
  },
}
