import React, { useContext, useState, useEffect } from 'react';
import abi from '../../ABIs/subContractAbi.json';
import { Web3WrapperContext } from '../../contexts/Web3WrapperProvider';
import { addresses } from '../../blockchain/constants';

export default function PublicVesting({ amount }) {

	const { web3Wrapper: wrapper } = useContext(Web3WrapperContext);
	const [releasedAmount, setReleasedAmount] = useState(0);

	const fetchClaimedTokens = async() => {
		if(wrapper){
			try {
			const data = await wrapper.getClaimedTokens(addresses.publicVesting, abi)
			setReleasedAmount(data);
			} catch (e){
				console.log(e);
				setReleasedAmount(0);
			}
		}
	}
	useEffect(() => {
		fetchClaimedTokens()	
	}, []);
	return (
		<div className='border-blue br-20 box-bg claim-grid-item'>
			<div className='closed-overlay'></div>
			<div className='round-label'>
				<div className='label'>
					<div className='closed-overlay'></div>
					<div className='label-circle'></div>
					<p>Public Vesting</p>
				</div>
				<div className='closed-label'>
					<p>Round Closed</p>
				</div>
			</div>
			<div className='round-info'>
				<div className='mr-60 mb-20'>
					<p className='name-text'>Total Allocation</p>
					<h2 className='prices-text'>{amount.toLocaleString('en-US')}</h2>
				</div>
				<div>
					<p className='name-text'>Claimed Tokens</p>
					<h2 className='prices-text'>{releasedAmount.toLocaleString('en-US')}</h2>
				</div>
				<div className='mr-60 mb-20'>
					<p className='name-text'>Available Tokens</p>
					<h2 className='prices-text'>{(amount - releasedAmount).toLocaleString('en-US')}</h2>
				</div>
				<div>
					{/* <p className='name-text'>Next Release</p>
					<h2 className='prices-text'>00:11:54:06</h2> */}
				</div>
				{ amount - releasedAmount === 0 ? <button className='claim-btn br-20' style={{opacity: 0.5}} disabled>Not available</button>
				:
				<button onClick={() => wrapper.claim(addresses.publicVesting, abi)} className='claim-btn br-20'>Claim</button>
				}
			</div>
		</div>
	)
}
