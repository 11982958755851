import React from 'react'
import loader from '../images/logos/white_icon.png';
import '../css/loader.css'
export default function Loader() {
    return (
        <div className='loader'>
            <img src={loader} alt="loader" />
        </div>
    )
}

