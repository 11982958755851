import { Routes, Route } from "react-router-dom";
import './css/App.css';
import Header from './components/Header';
import LandingPage from './components/LandingPage'
import Footer from './components/Footer';
import Staking from './components/Staking';
import Claim from './components/Claim';
import MobileNav from './components/MobileNav';
import { createContext, useState, useEffect } from "react";

function App() {

  return (
    <div className="App">
        <Header/>
        <main className='container'>
          <Routes>
            <Route path='/' element={<LandingPage />}/>
            <Route path='/staking' element={<Staking />}/>
            <Route path='/claim' element={<Claim/>}/>
          </Routes>
        </main>
        <Footer/>
        <MobileNav/>
    </div>
  );
}

export default App;
