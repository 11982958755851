import React, {useState, useEffect} from 'react';
import '../css/mobileNav.css';
import { NavLink, useLocation } from 'react-router-dom';

export default function MobileNav() {
    const [selector, setSelector] = useState(0)
    const location = useLocation();

    useEffect(() => {
        switch (location.pathname) {
            case '/staking':
                setSelector(33.33)
            break;
            case '/claim':
                setSelector(66.66)
            break;
            default:
                setSelector(0)
        }
    }, [location]);
    return (
        <div className='mobile-nav-container'>
            <nav className='mobile-nav'>
                <ul>
                    <li>
                        <NavLink className='nav-link' style={{textDecoration: 'none'}} to='/'>Dashboard</NavLink>
                    </li>
                    <li>
                        <NavLink className='nav-link' to='/staking'>Staking</NavLink>
                    </li>
                    <li>
                        <NavLink className='nav-link' to='/claim'>Claim</NavLink> 
                    </li>
                </ul>
                <div className='mobile-selection'>
                    <div className='mobile-selector' style={{left: selector+'%'}}></div>
                </div>
            </nav>
        </div>    
    )
}
