import React, {useRef, useEffect,useState, useContext} from 'react';
import StrategicVesting from './Rounds/StrategicVesting';
import PreSeedVesting from './Rounds/PreSeedVesting';
import SeedVesting from './Rounds/SeedVesting';
import PrivateAVesting from './Rounds/PrivateAVesting';
import PublicVesting from './Rounds/PublicVesting';
import TeamVesting from './Rounds/TeamVesting';
import AdvisorVesting from './Rounds/AdvisorVesting';
import MarketingVesting from './Rounds/MarketingVesting';
import DevelopmentVesting from './Rounds/DevelopmentVesting';
import ClaimInfo from './ClaimInfo';
import amountLoader from '../images/loaders/Pulse-1s-200px.svg';
import Loader from './Loader';
import axios from 'axios';
import '../css/claim.css';
import { Web3ModalContext } from '../contexts/Web3ModalProvider';
import useAccountData from '../hooks/useAccountData';

export default function Claim() {
    const scroll = useRef(null);
    const mainContract = '0x38D1d4b10628D5f3460757beD3215e2D85deFb3f';
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [roundIndex, setRoundIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [pageLoading, setPageLoading] = useState(true)
    const [walletBalance, setWalletBalance] = useState();
    const { account } = useContext(Web3ModalContext);
    const [availableRounds, setAvailableRounds] = useState([])

    const accountData = useAccountData();

    const rounds = [
        <StrategicVesting/>, 
        <PreSeedVesting/>, 
        <SeedVesting/>, 
        <PrivateAVesting/>, 
        <PublicVesting/>, 
        <TeamVesting/>,
        <AdvisorVesting/>,
        <MarketingVesting/>,
        <DevelopmentVesting/>
    ] 
    const roundNames = [
        'Strategic Vesting', 
        'PreSeed Vesting', 
        'Seed Vesting',
        'Private A Vesting',
        'Public Vesting',
        'Team Vesting',
        'Advisor Vesting',
        'Marketing Vesting',
        'Development Vesting'
    ]

    const getRounds = async () => {
        console.log("account => ", account);
        const url = 'https://claim-api.inery.io:3101/whitelist/checkWalletAddress';
        const data = {wallet_address: account}
        const headers = {
            'Content-Type': 'application/json',
            'authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjoiZm9vYmFyIiwiaWF0IjoxNjQyNjg2MDc1fQ.lkr5AzsSga1DlSWd7Hsr_CdPpof-aPIQQo-bpJ1NqTQ'
        }
        if(account){
            await axios.post(url, data,{
                headers: headers
            })
            .then(res=> {
                console.log('available rounds => ', res.data.data.rounds)
                setAvailableRounds(res.data.data.rounds)
            })
            .catch(err=> console.log(err))
            setPageLoading(false)
        }
    }

    const setWidth = () => {
        setScreenWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', setWidth)
        return () => {
            window.removeEventListener('resize', setWidth)
        };
    }, [screenWidth]);
    
    useEffect(() => {
        const slider = scroll.current;
        let isDown = false;
        let startX;
        let scrollLeft;
        const onMouseDown = (e) => {
            isDown = true;
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        }
        const onMouseLeave = () => {
            isDown = false;
        }
        const onMouseUp = () => {
            isDown = false;
        }
        const onMouseMove = (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = x - startX;
            slider.scrollLeft = scrollLeft - walk;
        }

        if(scroll && scroll.current){
            slider.addEventListener('mousedown', onMouseDown);
            slider.addEventListener('mouseup', onMouseUp);
            slider.addEventListener("mouseleave", onMouseLeave);
            slider.addEventListener('mousemove', onMouseMove);
        }
        /* return () => {
            slider.removeEventListener('mousedown', onMouseDown)
            slider.removeEventListener('mouseup', onMouseUp) 
            slider.removeEventListener('mousemove', onMouseLeave)
            slider.removeEventListener('mouseleave', onMouseMove)
        }; */
    }, []);

    useEffect(() => {
        if(account){
            getRounds();
        }else {
            setTimeout(() => {
                setPageLoading(false)
            }, 1500);
        }
    }, [account]);

    useEffect(() => {
        if (accountData) {
            console.log("accountData => ", accountData)
            setWalletBalance(accountData.ineryBalance);
            setLoading(false);
        }
    }, [accountData]);
    if (pageLoading){
        return <Loader/>
    }else {
        return (
            <div className='claim'>
                {account ? availableRounds.length === 0 ? <ClaimInfo text={`I cannot claim my $INR tokens? Users who have been successfully whitelisted and participated in the Inery ITO can claim $INR tokens. If you have not been whitelisted and/or did not participate in the token sale, you cannot claim $INR.`}/> 
                : <React.Fragment>
                    <div ref={scroll} className='mobile-scroll'>
                    {availableRounds.length !== 0 ? 
                        availableRounds.map((round, i)=> (
                            <p className={i === 0 ? 'active' : null} key={i} onClick={(e)=>{
                                const parent = e.currentTarget.parentElement;
                                const children = parent.querySelectorAll('p');
                                children.forEach((child)=> {
                                    child.classList.remove('active')
                                })
                                e.currentTarget.classList.add('active')
                                setRoundIndex(i);
                            }}>{round.round_name}</p>
                        ))
                    :
                        roundNames.map((name, i)=> ( 
                            <p className={i === 0 ? 'active' : null} key={i} onClick={(e)=>{
                                const parent = e.currentTarget.parentElement;
                                const children = parent.querySelectorAll('p');
                                children.forEach((child)=> {
                                    child.classList.remove('active')
                                })
                                e.currentTarget.classList.add('active')
                                setRoundIndex(i);
                            }}>{name}</p>
                        ))}
                </div>
                <div className='wallet-information'>
                    <div style={{textAlign: 'center'}}>
                        <p className='name-text'>Wallet Balance</p>
                        <h2 className='prices-text'>
                            {loading ?
                            <img src={amountLoader} alt='loader' width={40}/>
                            :
                            walletBalance === 0 ? walletBalance.toFixed(1) + ' INR': walletBalance.toLocaleString('en-US') + ' INR'
                            }
                        </h2>
                    </div>
                </div>
                    <div className='claim-grid'>
                        {screenWidth <= 900 ? <React.Fragment>{React.cloneElement(rounds[roundIndex+1], {amount: availableRounds[roundIndex].amount})}</React.Fragment>
                        : availableRounds.map((round, i)=>(
                            <React.Fragment key={i}>{React.cloneElement(rounds[round.round_name_id-1], {amount: round.amount})}</React.Fragment>
                        ))}
                        
                        
                    </div>
                </React.Fragment> :  <ClaimInfo text={`I participated in the $INR ITO but I am unable to check if I can claim $INR? To check if you are qualified for the claim, go to ‘Connect Your Wallet’. Once you have connected your wallet, you can check your claimable $INR.`}/>}
            </div>
        )
    }
}
