import React from 'react'
import '../css/staking.css'
export default function Staking() {
    return (
        <div className='staking'>
            <div className='staking-info border-blue br-20 box-bg mb-20'>
                    <div className='label'>
                        <div className='label-circle'></div>
                        <p>Info</p>
                    </div>
                    <div className='staking-info-text'>
                        <p>This page is currently unavailable. Coming soon...</p>
                    </div>
                </div>
            </div>
    )
}
