import React, {useState, useEffect, useContext, useCallback} from 'react';
/* import { useWeb3React } from '@web3-react/core'; */
import logo from '../images/logos/Inery-logo-white.png';
import '../css/header.css';
import { NavLink, useLocation } from 'react-router-dom';
import WalletIdPopUp from './WalletIdPopUp';

import { Web3ModalContext } from '../contexts/Web3ModalProvider';

export default function Header() {
    const [selector, setSelector] = useState(0);
    const [displayFullId, setDisplayFullId] = useState(false);
    const location = useLocation();

    const { connect, disconnect, account } = useContext(Web3ModalContext);
  
    const handleConnectWallet = useCallback(() => {
        connect();
    }, [connect]);

    const handleDisconnectWallet = useCallback(() => {
        disconnect();
    }, [disconnect]);

    useEffect(() => {
        switch (location.pathname) {
            case '/staking':
                setSelector(33.33)
            break;
            case '/claim':
                setSelector(66.66)
            break;
            default:
                setSelector(0)
        }
    }, [location]);

    const closeFullIdPopUp = () => {
        setDisplayFullId(false)
    }
    return (
        <header>
            <NavLink to='/'>
                <img className='logo' src={logo} alt='logo'/>
            </NavLink>
            <nav>
                <ul>
                    <li>
                        <NavLink className='nav-link' style={{textDecoration: 'none'}} to='/'>Dashboard</NavLink>
                    </li>
                    <li>
                        <NavLink className='nav-link' to='/staking'>Staking</NavLink>
                    </li>
                    <li>
                        <NavLink className='nav-link' to='/claim'>Claim</NavLink> 
                    </li>
                </ul>
                <div className='selection'>
                    <div className='selector' style={{left: selector+'%'}}></div>
                </div>
            </nav>
            <div className='connect'>
                <button onClick={()=> {
                    console.log("account => ", account);
                    if(account) {
                        handleDisconnectWallet()
                    } else {
                        handleConnectWallet()
                    }
                }}>{account ? 'Disconnect wallet' : 'Connect wallet'}
                </button>
                {account ? <div onClick={()=>{
                        setDisplayFullId(true)
                }} className='user-wallet-code border-blue'>
                    <p>
                        {account}
                    </p>
                </div>: null}
            </div>
            <WalletIdPopUp id={account} active={displayFullId} closePopUp={closeFullIdPopUp}/>
        </header>
    )
}

